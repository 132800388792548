import { BreadcrumbItemData } from '@medm-ui/breadcrumb';
import { AppRoute } from 'models';
import { PathHelper } from '../path-helper';

export function getLocalPath(contentPath: string, routeMap: any, productKey?: string): string {
    let productRouteMap = routeMap;
    if (productKey) {
        productRouteMap = productRouteMap.filter((x: { productKey: string; }) => x.productKey === productKey);
    }
    const hashRouted = contentPath.startsWith('#/');
    const appPathWithoutExtras = hashRouted
        ? contentPath
        : PathHelper.removeQueryAndFragment(contentPath);

    const routeInfo = productRouteMap.find((x: any) => !!x.contentPathRegex && !!appPathWithoutExtras.match(x.contentPathRegex));
    if (!routeInfo) {
        return undefined;
    }

    const routeParams = extractRouteParameters(appPathWithoutExtras, routeInfo.contentPathRegex);
    let pathExtras;
    if (hashRouted) {
        pathExtras = "";
    } else if (routeInfo.queryMap) {
        const queryParams = PathHelper.getParams(contentPath);
        for (const routeParam in routeInfo.queryMap) {
            const queryParam = routeInfo.queryMap[routeParam];
            const value = queryParams[queryParam];
            if (value !== undefined) {
                routeParams[routeParam] = value;
                delete queryParams[queryParam];
            }
        }
        pathExtras = PathHelper.buildQuery(queryParams) + PathHelper.getFragment(contentPath);
    } else {
        pathExtras = PathHelper.getQueryAndFragment(contentPath);
    }

    return applyRouteParameters(routeInfo.localPathDefinition, routeParams) + pathExtras;
}

export function getContentPath(localPath: string, routeMap: any, productKey?: string): string {
    let productRouteMap = routeMap;
    if (productKey) {
        productRouteMap = productRouteMap.filter((x: { productKey: string; }) => x.productKey === productKey);
    }
    const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
    const routeInfo = productRouteMap.find((x: any) => !!localPathWithoutExtras.match(x.localPathRegex));
    if (!routeInfo) {
        return undefined;
    }

    const hashRouted = routeInfo.contentPathDefinition.startsWith('#/');

    const routeParams = extractRouteParameters(localPathWithoutExtras, routeInfo.localPathRegex);

    let pathExtras;
    if (hashRouted) {
        pathExtras = "";
    } else if (routeInfo.queryMap) {
        const queryParams = PathHelper.getParams(localPath);
        for (const routeParam in routeInfo.queryMap) {
            const value = routeParams[routeParam];
            if (value !== undefined) {
                const queryParam = routeInfo.queryMap[routeParam];
                queryParams[queryParam] = value;
                delete routeParams[routeParam];
            }
        }
        pathExtras = PathHelper.buildQuery(queryParams) + PathHelper.getFragment(localPath);
    } else {
        pathExtras = PathHelper.getQueryAndFragment(localPath);
    }

    return applyRouteParameters(routeInfo.contentPathDefinition, routeParams) + pathExtras;
}

export function getRouteByLocalPath<T>(localPath: string, routeMap: any): any {
    const pathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
    const result = routeMap.find((x: any) => localPath.match(x.localPathRegex));
    return result ? result as unknown as AppRoute<T> : undefined;
}

export function getBreadcrumbData(localPath: string, routeMap: any): BreadcrumbItemData[] {
    const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
    const routeInfo = routeMap.find((x: any) => !!localPathWithoutExtras.match(x.localPathRegex));

    const breadCrumbData: BreadcrumbItemData[] = [];

    if (routeInfo) {
        breadCrumbData.push({
            key: routeInfo.productKey,
            label: routeInfo.productName
        },
            {
                key: routeInfo.title,
                label: routeInfo.title
            }
        )
    }
    return breadCrumbData;
}

export function getRouteInfo(localPath: string, routeMap: any): any {
    const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
    const routeInfo = routeMap.find((x: any) => !!localPathWithoutExtras.match(x.localPathRegex));

    if (routeInfo) {
        return routeInfo;
    }
    return null;
}

export function getPathMatchRegex(pathDef: string): RegExp {
    const parsed = pathDef
        .replace(/^\//, '')
        .replace(/\/$/, '')
        .split('/')
        .map((x, i) => {
            const exp1 = x.replace(/^:(\w+)/, `(?:[/]${i ? '+' : '*'}(?<$1>[^/?]+))`);
            const exp2 = x.replace(/^(\w+)/, `(?:[/]${i ? '+' : '*'}$1)`);
            return x.startsWith(':') ? exp1 : exp2;
        });
    return new RegExp(`^${parsed.join('')}[/]*$`, 'i');
}

function extractRouteParameters(pathWithoutExtras: string, pathRegex: RegExp): any {
    const match = pathRegex.exec(pathWithoutExtras);
    return match ? (match.groups || {}) : {};
}

function applyRouteParameters(pathDefinition: string, parameters: any): string {
    let target = pathDefinition || '';

    const placeholders = target.match(/(:[^/]+)/g);
    if (placeholders) {
        placeholders.forEach(x => target = target.replace(x, parameters[x.replace(/[:?]/g, '')] || ''));
        target = target.replace(/\/\//g, '/');
    }

    return target;
}
