import { Manifest } from "./product";

export class UnionConfig {
  apiUrl: string;
  debugMode: boolean;
  sandboxEnabled: boolean;
  auth: AuthConfig;
  defaultEnvironment: string;
  manifest: Manifest;
  appInsight: string;
  userIdleTimeoutInSeconds: UserIdleConfig;
}

class AuthConfig {
  authority: string;
  client_id: string;
  logout: string;
}

export class UserIdleConfig {
  idle: number;
  timeout: number;
}
