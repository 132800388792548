import { Button } from "@medm-ui/button";
import { useAppSelector } from "redux/hooks";
import { UnionConfig } from "models/union-config";
import { useEffect, useState } from "react";
import "./timeout.css";
import { SessionStorageService } from "services/storage-service";
import { UnionDialog } from "components/shared/dialog/dialog";
import { DialogActionsBar } from "@medm-ui/dialog";

export function Timeout(props: any) {
  const unionConfig = useAppSelector<UnionConfig>(
    (state) => state.unionConfig.unionConfig
  );
  const [valueToDisplay, setValueToDisplay] = useState(
    secsToMinutesAndSeconds(unionConfig.userIdleTimeoutInSeconds.timeout)
  );
  const [counter, setCounter] = useState(
    unionConfig.userIdleTimeoutInSeconds.timeout
  );

  useEffect(() => {
    if (
      new Date() >
      new Date(
        SessionStorageService.getItem("autoLogoutTime", "autoLogoutTime")
      )
    ) {
      timeout();
    } else {
      const sessionExpiration = Math.ceil(
        (new Date(
          SessionStorageService.getItem("autoLogoutTime", "autoLogoutTime")
        ).getTime() -
          new Date().getTime()) /
        1000
      );
      if (sessionExpiration < counter) {
        setValueToDisplay(secsToMinutesAndSeconds(sessionExpiration));
        setCounter(sessionExpiration);
      }
    }
  });

  useEffect(() => {
    counter > 0 &&
      setTimeout(() => {
        setValueToDisplay(secsToMinutesAndSeconds(counter - 1));
        setCounter(counter - 1);
      }, 1000);
    if (counter === 0 && unionConfig.userIdleTimeoutInSeconds) {
      props.showModalHandler();
      timeout();
    }
  }, [valueToDisplay]);

  const timeout = () => {
    updateLastActiveProduct();
    sessionStorage.clear();
    const logoutRedirectUrl =
      unionConfig.auth.logout +
      "&clientId=" +
      unionConfig.auth.client_id +
      "&postLogoutRedirectUrl=" +
      window.location.origin +
      "/timeout.html";
    window.location.assign(logoutRedirectUrl);
  };

  const updateLastActiveProduct = () => {
    let activeProduct = JSON.parse(localStorage.getItem("activeProduct"));
    let lastActiveProduct = JSON.parse(
      localStorage.getItem("lastActiveProduct")
    );
    activeProduct = activeProduct === null ? [] : activeProduct;
    lastActiveProduct = lastActiveProduct === null ? [] : lastActiveProduct;
    activeProduct.filter((data: any) => {
      if (lastActiveProduct?.includes(data)) {
        return;
      } else lastActiveProduct.push(data);
    });
    localStorage.setItem(
      "lastActiveProduct",
      JSON.stringify(lastActiveProduct)
    );
    localStorage.removeItem("activeProduct");
  };

  const children = (
    <>
      <div className="timeout-modal-container">
        <p className="container-text">
          Your session is about to be timed out due to inactivity. Select to
          either continue with the session or to logout.
        </p>
        <p className="container-text last-paragraph" data-test-id="timeout-last-paragraph">
          The system will automatically log you out in: <br />{" "}
          <span className="countdown-text">{valueToDisplay}</span> minutes.
        </p>
      </div>
      <DialogActionsBar layout="end">
        <Button
          buttonLabel="Continue session"
          buttonSize="medium"
          buttonType="Primary"
          buttonId="continue-session"
          onButtonClick={() => {
            props.showModalHandler(false);
          }}
        />
        <Button
          buttonLabel="Log out"
          buttonSize="medium"
          buttonType="Subtle"
          buttonId="logout"
          onButtonClick={() => {
            window.location.assign(unionConfig.auth.logout);
          }}
        />
      </DialogActionsBar>
    </>
  );
  return (
    <div>
      <UnionDialog
        id={props.id}
        title="Session Timeout"
        children={children}
        closeIcon={false}
        width={"480px"}
        className="timeout-dialog"
      ></UnionDialog>
    </div>
  );
}

function secsToMinutesAndSeconds(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = (duration % 60).toFixed(0);
  return Number(seconds) === 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (Number(seconds) < 10 ? "0" : "") + seconds;
}
