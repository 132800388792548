import { PermissionedResource, PermissionSet } from "models";

export function getPermission(resource: PermissionedResource, permissionSet: PermissionSet): boolean {
    if (resource && resource.permissions && resource.permissions.length) {
        if (!permissionSet || !permissionSet[resource.productKey]) {
            return false;
        }

        let hasAnyPermission = false;
        let hasMissingPermission = false;

        resource.permissions.forEach((routePermission: any) => {
            const routePermissionName = routePermission['name'] || routePermission;
            const routePermissionAccessLevel = routePermission['accessLevel'] || 0;

            const userPermission: any = permissionSet[resource.productKey]
                .find((x: any) => x === routePermissionName || x["name"] === routePermissionName);

            if (userPermission) {
                const userPermissionAccessLevel = userPermission['accessLevel'] || 0;
                if (userPermissionAccessLevel >= routePermissionAccessLevel) {
                    hasAnyPermission = true;
                } else {
                    hasMissingPermission = true;
                }
            } else {
                hasMissingPermission = true;
            }
        });

        if (resource.permissionRequirement === 'All') {
            return !hasMissingPermission;
        } else {
            return hasAnyPermission;
        }
    } else {
        if (permissionSet && permissionSet[resource.productKey] && permissionSet[resource.productKey].length === 0) {
            return false;
        }
        return true;
    }
}