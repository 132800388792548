import { Navigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getPermission } from "services/permission/permission-helper";
import { getRouteInfo } from "services/router-helper";
import { isRestricted } from "services/permission/permission.service";
import { useEffect, useState } from "react";

export const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const permissionSet = useAppSelector((state) => state.permission.permission);
  const config = useAppSelector((state) => state.unionConfig.unionConfig);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const pageData = getRouteInfo(window.location.pathname, routeMap);

  const product = config?.manifest.find((x) => x.key === pageData.productKey);
  const isValid = getPermission(pageData, permissionSet);
  const [isEnvRestricted, setIsEnvRestricted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(product.environment.isRestricted);
    setIsEnvRestricted(product.environment.isRestricted);
  }, [product?.environment.isRestricted]);

  useEffect(() => {
    const fetchIsRestricted = async () => {
      await isRestricted(config, pageData.productKey)
        .then((response) => {
          if (response !== "false") {
            setIsEnvRestricted(true);
          } else {
            setIsEnvRestricted(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          setIsEnvRestricted(true);
        });
    };

    if (product?.environment.isRestricted) {
      fetchIsRestricted();
    }
  });

  if (!isValid) {
    return <Navigate to="/" />;
  }

  if (!loading) {
    if (isEnvRestricted) {
      return (
        <Navigate
          to="/unauthorise"
          state={{
            title: pageData.productName,
            key: pageData.productKey,
            icon: product.icon,
          }}
          replace={true}
        />
      );
    } else {
      return children;
    }
  }
};
