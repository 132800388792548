import React, { useEffect, useState } from "react";
import { UnionCard } from "components/shared/union-card";
import {
  LastVisitedPage,
  LastVisitedPages,
  Page,
  CardRow,
} from "models/last-visited-page/last-visited-page";
import { Label } from "@medm-ui/label";
import { MultiSelectDropdown, MultiSelectItem } from "@medm-ui/select-combobox";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getRouteInfo } from "services/router-helper";
import { getLastVisitedPages } from "services/last-visited-page";
import { useAppSelector } from "redux/hooks";
import { Button } from "@medm-ui/button";
import { getPermission } from "services/permission/permission-helper";
import "./home.css";

export function Home() {
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const permissionSet = useAppSelector((state) => state.permission.permission);
  const menu = useAppSelector((state) => state.menu.menu);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const [lastVisitedPages, setLastVisitedPages] = useState(
    [] as Array<LastVisitedPages>
  );
  const [displayedLVP, setDisplayedLVP] = useState(lastVisitedPages);
  const [data, setData] = useState([] as Array<MultiSelectItem>);
  const [lastVisitedData, setlastVisitedData] = useState(null);
  let cardClicked = false;
  const navigate = useNavigate();
  const style = {
    height: "inherit",
    width: "360px",
  };

  const CardsRow = ({ entry, numberOfCards }: CardRow) => {
    return (
      <React.Fragment>
        <div className="nameAndButtonSection" data-test-id="LVPRowNameAndButtonSection">
          <p
            className="lastVisitedAppName"
            id={entry.key}
            data-test-id={entry.key}
          >
            {entry.key}
          </p>
          {entry.pages.length > numberOfCards && (
            <Button
              buttonId="homeSeeAllButton"
              buttonLabel="See all"
              buttonType="Tertiary"
              widthSize="64px"
              heightSize="24px"
              className="seeAllButton"
              buttonSize="small"
              onButtonClick={() => {
                navigate(`lastVisited/${entry.key}`, {
                  state: entry,
                });
              }}
            />
          )}
        </div>
        <div className="lastVisitedPagesCards" data-test-id="lastVisitedPagesCardsRow">
          {entry.pages.length > numberOfCards
            ? entry.pages.slice(0, numberOfCards).map((page: Page, index) => (
              <UnionCard
                id={
                  page?.url?.includes("@") &&
                    page?.url?.includes("rfr-calculator")
                    ? `${page.pageName} : ${page.url.split("/")[3]}`
                    : page.pageName
                }
                width="320"
                page={page}
                isLastVisitedCard={true}
                key={index + "-card"}
                isClickable={true}
                onCardClick={() => {
                  if (!cardClicked) {
                    cardClicked = true;
                    if (page.popout) {
                      if (page.popout === true) {
                        window.open(page.url, "_blank");
                      } else {
                        window.open(
                          page.url,
                          page.popout.target,
                          page.popout.features
                        );
                      }
                    } else {
                      navigate(page.url, { replace: true });
                    }
                  } else {
                    cardClicked = false;
                  }
                }}
              />
            ))
            : entry.pages.map((page: Page, index) => (
              <UnionCard
                id={
                  page?.url?.includes("@") &&
                    page?.url?.includes("rfr-calculator")
                    ? `${page.pageName} : ${page.url.split("/")[3]}`
                    : page.pageName
                }
                width="320"
                page={page}
                isLastVisitedCard={true}
                key={index + "-card"}
                isClickable={true}
                onCardClick={() => {
                  if (!cardClicked) {
                    cardClicked = true;
                    if (page.popout) {
                      if (page.popout === true) {
                        window.open(page.url, "_blank");
                      } else {
                        window.open(
                          page.url,
                          page.popout.target,
                          page.popout.features
                        );
                      }
                    } else {
                      navigate(
                        page.url.includes(window.location.origin)
                          ? page.url.replace(window.location.origin, "")
                          : page.url.replace(
                            "https://dev.finapps.ihsmarkit.com",
                            ""
                          )
                      );
                    }
                  } else {
                    cardClicked = false;
                  }
                }}
              />
            ))}
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    getLastVisitedPages(unionConfig).then((results) => {
      setlastVisitedData(results);
    });
  }, []);

  useEffect(() => {
    const pages = [] as Array<LastVisitedPages>;
    if (lastVisitedData?.length > 0) {
      lastVisitedData.forEach((result: LastVisitedPage) => {
        //Checking if the users is having access to product and product is in menus[]
        if (menu?.length) {
          const hasProductAccess = menu.find(
            (item: any) => item.productKey === result.applicationName
          );
          if (hasProductAccess) {
            const product = unionConfig.manifest.find(
              (product) => product.key === result.applicationName
            );
            const icon = product?.icon;
            const productName = product?.name;
            const url =
              window.location.hostname === "localhost"
                ? result.visitedURL.replace(
                  "https://dev.finapps.ihsmarkit.com",
                  ""
                )
                : result.visitedURL.replace(window.location.origin, "");

            const pageData = getRouteInfo(url, routeMap);
            const hasPermission = getPermission(pageData, permissionSet);
            if (hasPermission) {
              // Display select company card only if user is assigned Multiple Company 
              if (pageData.productKey === 'compliance' && pageData.path === '/compliance/select-company') {
                if (permissionSet[pageData.productKey] !== null && !permissionSet[pageData.productKey].includes("MULTI-TENANT")) {
                  return;
                }
              }
              const pageName = getRouteInfo(url, routeMap)?.title;
              const index = pages.findIndex((page) => page.key === productName);
              if (index < 0) {
                pages.push({
                  key: productName,
                  pages: [
                    {
                      pageName: pageName,
                      lastOpened: moment
                        .utc(result.visitedDateTime)
                        .local()
                        .format("DD MMM YYYY, HH:mm"),
                      icon: {
                        icon: icon ? icon : "",
                        color: "#AAAAAA",
                        size: "3x",
                      },
                      url: url,
                      popout: pageData.popout,
                    },
                  ],
                });
              } else {
                pages[index].pages.push({
                  pageName: pageName,
                  lastOpened: moment
                    .utc(result.visitedDateTime)
                    .local()
                    .format("DD MMM YYYY, HH:mm"),
                  icon: {
                    icon: icon ? icon : "",
                    color: "#AAAAAA",
                    size: "3x",
                  },
                  url: url,
                  popout: pageData.popout,
                });
              }
            }
          }
        }
      });
    }
    setLastVisitedPages(pages);
    setDisplayedLVP(pages);
  }, [menu, lastVisitedData, unionConfig.manifest, routeMap, permissionSet]);

  useEffect(() => {
    const labels: MultiSelectItem[] = [];
    lastVisitedPages?.forEach((page: LastVisitedPages) => {
      if (menu.find((item: any) => item.label === page.key)) {
        if (page.key) {
          labels.push({
            text: page.key,
            value: page.key,
            tagValue: page.key,
            isSelected: false,
          });
        }
      }
    });
    setData(labels);
  }, [lastVisitedPages]);

  const handleMultiselectValueChange = (value: any) => {
    if (value.length > 0) {
      const pages = [] as Array<LastVisitedPages>;
      value.forEach((v: string) => {
        const product = lastVisitedPages.find((page) => page.key === v);
        pages.push(product);
      });
      setDisplayedLVP(pages);
    } else {
      setDisplayedLVP(lastVisitedPages);
    }
  };

  return (
    <React.Fragment>
      <Label
        label="Welcome back"
        size="Large"
        type="Header"
        weight="Bold"
        color="#222222"
        className="homePageTitleLabel"
      />
      <Label
        label="Access your financial S&P Global applications in one, connected experience."
        size="Small"
        type="Body"
        weight="Regular"
        color="#666666"
        className="homePageSubtitleLabel"
      />
      <div className="selectSection">
        <Label
          label="Your last visited"
          size="Medium"
          type="Body"
          weight="Bold"
          color="#222222"
          className="lastVisitedLabel"
        />
        <MultiSelectDropdown
          data={data}
          id="multiselect"
          size="medium"
          style={style}
          placeholder="Select an application"
          noOfTagsToDisplay={2}
          preventClose={false}
          onValueChange={handleMultiselectValueChange}
        />
      </div>
      <span style={{ overflow: "auto", paddingBottom: "24px" }}>
        {displayedLVP &&
          displayedLVP.map((entry: LastVisitedPages) => {
            return (
              <div className="lastVisitedPagesContainer">
                <span className="small-card-row">
                  <CardsRow entry={entry} numberOfCards={4} />
                </span>
                <span className="medium-card-row">
                  <CardsRow entry={entry} numberOfCards={5} />
                </span>
                <span className="large-card-row">
                  <CardsRow entry={entry} numberOfCards={6} />
                </span>
              </div>
            );
          })}
      </span>
    </React.Fragment>
  );
}
