import { ButtonProps } from "@medm-ui/button";
import { UnionModal } from "../../components/shared/modal";
import { useAppSelector } from "../../redux/hooks";
import { VersionInfo } from "models/version-data/version-info";
import "./about.css";

export function About(props: any) {
  const versionsInfo = useAppSelector<VersionInfo>(
    (state) => state.versions.versions
  );
  function handleClose() {
    props.showAboutHandler(false);
  }
  const act = [
    {
      buttonId: "okButton",
      buttonLabel: "Ok",
      buttonType: "Primary",
      disabled: false,
      onButtonClick: () => {
        props.showAboutHandler(false);
      },
    },
  ] as ButtonProps[];
  return (
    <div className="modal-container">
      {versionsInfo && (
        <UnionModal
          id={props.id}
          title="Application Version"
          size="small"
          actions={act}
          onClose={handleClose}
          actionsLayout={"start"}
        >
          <table className="about-container">
            <tbody className="about-container union-products-container">
              <div className="item first-item">
                <th className="row">FinApps UI</th>
                <td className="row">{versionsInfo.ui}</td>
              </div>
              <div className="item">
                <th className="row">FinApps API</th>
                <td className="row">{versionsInfo.api}</td>
              </div>
            </tbody>
            <tbody className="about-container">
              <table>
                <tr className="row" aria-label="versionsRow">
                  <th className="item first-item">Product</th>
                  <th className="item">UI</th>
                  <th className="item">API</th>
                </tr>
                {versionsInfo.apps.map((app) => {
                  return (
                    <tr key={app.productName} className="row" aria-label="versionsRow">
                      <td className="item first-item">{app.productName}</td>
                      <td className="item">{app.ui}</td>
                      <td className="item">{app.api}</td>
                    </tr>
                  );
                })}
              </table>
            </tbody>
          </table>
        </UnionModal>
      )}
    </div>
  );
}
