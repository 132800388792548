import { Dialog, DialogProps } from "@medm-ui/dialog";

export function UnionDialog(props: DialogProps) {
  return (
    <Dialog
      children={props.children}
      title={props.title}
      onClose={props.onClose}
      id={props.id}
      width={props.width}
      className={props.className}
      closeIcon={props.closeIcon}
    ></Dialog>
  );
}
